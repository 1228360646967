<template>
  <div id="holWrap" :class="{'hol_menuBg' : holTab === 'menu1'}">
    <ul class="menuWrap">
      <li @click="holTab = 'menu1'" :class="{ 'currentMenu' : holTab === 'menu1'}">홀덤 플레이</li>
      <li @click="holTab = 'menu2'" :class="{ 'currentMenu' : holTab === 'menu2'}">홀덤 충전</li>
      <li @click="holTab = 'menu3'" :class="{ 'currentMenu' : holTab === 'menu3'}">홀덤 환전</li>
    </ul>
    <div class="holContWrap" v-if="holTab === 'menu1'" :class="{'hol_menu1' : holTab === 'menu1'}">
      <div class="holtitle">
        <img src="@/assets/img/hol/hol_logo.png">
        <p>승부사 본능을 깨워라!<br> 치열한 두뇌싸움의 정석<br>최고의 홀덤 게임에 지금 도전하세요.</p>
      </div>
      <template v-for="item in commonCodeByOrder['minigame']" v-bind:key="item.vendorKey" >
        <button v-if="item.code === 'hol'" @click="onCasinoSelectGame(item.groupCode, item.code)">실행</button>
      </template>
    </div>
    <div class="holContWrap holTab2" v-if="holTab === 'menu2'">
      <div class="tabBg"></div>
      <div class="hol_content">
        <div class="hol-noti">
          <h4>확인 및 필독사항</h4>
          <ul>
            <li>홀덤 게임을 플레이하기 위해서는 홀덤 머니로 보유금 전환을 하셔야 합니다.</li>
            <li>충전하실 금액을 선택하거나 입력해 주세요.</li>
            <li>확인 버튼을 클릭하시면 전환이 완료됩니다.</li>
            <li>입금액은 1,000원이상 1,000원 단위입니다.</li>
            <li>기존 입금액은 최근 5회까지 조회됩니다.</li>
          </ul>
        </div>
        <div class="hol-charge">
          <table>
            <tr>
              <td>현재 보유머니</td>
              <td><span class="money">{{ thousand(userMoney ? userMoney.toString(): '0') }}</span> 원</td>
            </tr>
            <tr>
              <td>홀덤 머니</td>
              <td><span class="money">{{ thousand(holMoney) }}</span> 원</td>
            </tr>
            <tr>
              <td>홀덤 전환 금액</td>
              <td>
                <div>
                  <input pattern="[0-9.,]+" v-model="setCurrentChargeMoney" :readonly="true" @focus="onFocus"> 원
                </div>
                <ul class="cashBtnWrap">
                  <li><button @click="setMoney(10000)">1만원</button></li>
                  <li><button @click="setMoney(30000)">3만원</button></li>
                  <li><button @click="setMoney(50000)">5만원</button></li>
                  <li><button @click="setMoney(100000)">10만원</button></li>
                  <li><button @click="setMoney(300000)">30만원</button></li>
                  <li><button @click="setMoney(500000)">50만원</button></li>
                  <li><button @click="setMoney(1000000)">100만원</button></li>
                  <li><button @click="setMoney(0)">정정</button></li>
                </ul>
              </td>
            </tr>
          </table>
        </div>
        <button class="exBtn" @click="onCharge">홀덤머니로 전환하기</button>
        <h3>홀덤 전환내역</h3>
        <div class="hol-list">
          <table>
            <colgroup>
              <col style="width: 10%;">
              <col style="width: 30%;">
              <col style="width: 30%;">
              <col style="width: 30%;">
            </colgroup>
            <tr>
              <th>번호</th>
              <th>전환일자</th>
              <th>전환금액</th>
              <th>처리현황</th>
            </tr>
              <template v-if="holemList.length > 0">
                <template v-for="item in holemList" :key="item.cashIdx">
                  <tr>
                    <td>{{item.cashIdx}}</td>
                    <td>{{item.updDate}}</td>
                    <td>{{thousand(item.cashAmt)}}원</td>
                    <td>처리</td>
                  </tr>
                </template>
                <!--                <td>1</td>-->
                <!--                <td>2024-04-16</td>-->
                <!--                <td>1,000,000원</td>-->
                <!--                <td>처리</td>-->
              </template>
              <template v-else>
                <tr>
                  <td colspan="3">내역이 없습니다.</td>
                </tr>
              </template>
          </table>
        </div>
<!--        <button class="cancelBtn">전체 삭제</button>-->
      </div>
    </div>
    <div class="holContWrap holTab2" v-if="holTab === 'menu3'">
      <div class="tabBg"></div>
      <div class="hol_content">
        <div class="hol-noti">
          <h4>확인 및 필독사항</h4>
          <ul>
            <li>언제든 홀덤 머니를 보유금으로 전환하실 수 있습니다.</li>
            <li>확인 버튼을 클릭하시면 보유하신 홀덤 머니 전환이 완료됩니다.</li>
          </ul>
        </div>
        <div class="hol-charge">
          <table>
            <tr>
              <td>현재 보유머니</td>
              <td><span class="money">{{ thousand(userMoney ? userMoney.toString(): '0') }}</span> 원</td>
            </tr>
            <tr>
              <td>홀덤 머니</td>
              <td><span class="money">{{ thousand(holMoney) }}</span> 원</td>
            </tr>
            <tr>
              <td>보유금 전환 금액</td>
              <td>
                <div>
                  <input pattern="[0-9.,]+" v-model="getCurrentChargeMoney" :readonly="true" @focus="onFocus"> 원
                </div>
                <ul class="cashBtnWrap">
                  <li><button @click="getMoney(10000)">1만원</button></li>
                  <li><button @click="getMoney(30000)">3만원</button></li>
                  <li><button @click="getMoney(50000)">5만원</button></li>
                  <li><button @click="getMoney(100000)">10만원</button></li>
                  <li><button @click="getMoney(300000)">30만원</button></li>
                  <li><button @click="getMoney(500000)">50만원</button></li>
                  <li><button @click="getMoney(1000000)">100만원</button></li>
                  <li><button @click="getMoney(0)">정정</button></li>
                </ul>
              </td>
            </tr>
          </table>
        </div>
        <button class="exBtn" @click="onExchange">보유금으로 전환하기</button>
        <h3>보유금 전환내역</h3>
        <div class="hol-list">
          <table>
            <colgroup>
              <col style="width: 10%;">
              <col style="width: 30%;">
              <col style="width: 30%;">
              <col style="width: 30%;">
            </colgroup>
            <tr>
              <th>번호</th>
              <th>환전일자</th>
              <th>환전금액</th>
              <th>처리현황</th>
            </tr>
              <template v-if="holemList.length > 0">
                <template v-for="item in holemList" :key="item.cashIdx">
                  <tr>
                  <td>{{item.cashIdx}}</td>
                  <td>{{item.updDate}}</td>
                    <td>{{thousand(item.cashAmt)}}원</td>
                  <td>처리</td>
                  </tr>
                </template>
                <!--                <td>1</td>-->
                <!--                <td>2024-04-16</td>-->
                <!--                <td>1,000,000원</td>-->
                <!--                <td>처리</td>-->
              </template>
              <template v-else>
                <tr>
                  <td colspan="3">내역이 없습니다.</td>
                </tr>
              </template>
          </table>
        </div>
<!--        <button class="cancelBtn">전체 삭제</button>-->
      </div>
    </div>
  </div>

  <transition name="fade">
    <TMmodal v-show="TMmodalCharge" :isOpen="TMmodalCharge" :type="'header'" @close="onChargeSubmit" @cancel="onChargeCancel"/>
  </transition>
  <transition name="fade">
    <TMmodal v-show="TMmodalExchange" :isOpen="TMmodalExchange" :type="'header'" @close="onExchangeSubmit" @cancel="onExchangeCancel"/>
  </transition>
</template>

<script>
import { mapState } from 'vuex'
import { chargeHolem, exchangeHolem, getHoldemList, getHoldemMoney } from '@/api/game'
import { getDateStr, getSubDaysDate, thousand } from '@/libs/utils'
import TMmodal from '@/components/common/TotalMoney.vue'

export default {
  name: 'holdemMain',
  components: { TMmodal },
  data () {
    return {
      userMoney: 0,
      holTab: 'menu2',
      holMoney: 0,
      defaultHolMoney: 0,
      setCurrentChargeMoney: '0',
      getCurrentChargeMoney: '0',
      holemList: [],
      TMmodalCharge: false,
      TMmodalExchange: false
    }
  },
  computed: {
    ...mapState([
      'commonCode',
      'commonCodeByOrder',
      'gameCount',
      'userData'
    ])
  },
  watch: {
    async holTab () {
      this.userMoney = this.userData.cashAmt
      if (this.holTab === 'menu2') {
        await this.getChargeList('NH1')
      } else if (this.holTab === 'menu3') {
        await this.getChargeList('NH2')
      }
      this.getHoldemMoney()
    },
    async setCurrentChargeMoney (newValue, oldValue) {
      if (!this.userData.cashAmt) {
        this.setCurrentChargeMoney = '0'
        return
      }
      // this.setCurrentChargeMoney = thousand(this.setCurrentChargeMoney)
      const parts = this.setCurrentChargeMoney.split('.')
      const v = parts[0].replace(/\D/g, '')
      const dec = parts[1]
      const calcNum = Number((dec !== undefined ? v + '.' + dec : v))
      // use this for numeric calculations
      console.log('number for calculations: ', calcNum)
      let n = new Intl.NumberFormat('en-EN').format(v)
      n = dec !== undefined ? n + '.' + dec : n

      if (this.userData.cashAmt - (Number(n.replace(/,/g, ''))) < 0) {
        await this.onCheck('현재 보유머니가 부족합니다.')
        this.setCurrentChargeMoney = oldValue
        return
      }

      this.setCurrentChargeMoney = n

      // this.userMoney = this.userData.cashAmt - (Number(n.replace(/,/g, '')))
      // this.holMoney = this.defaultHolMoney + (Number(n.replace(/,/g, '')))
      //
    },
    async getCurrentChargeMoney (newValue, oldValue) {
      if (!this.defaultHolMoney) {
        this.getCurrentChargeMoney = '0'
        return
      }
      // this.setCurrentChargeMoney = thousand(this.setCurrentChargeMoney)
      const parts = this.getCurrentChargeMoney.split('.')
      const v = parts[0].replace(/\D/g, '')
      const dec = parts[1]
      const calcNum = Number((dec !== undefined ? v + '.' + dec : v))
      // use this for numeric calculations
      console.log('number for calculations: ', calcNum)
      let n = new Intl.NumberFormat('en-EN').format(v)
      n = dec !== undefined ? n + '.' + dec : n

      if (this.defaultHolMoney - (Number(n.replace(/,/g, ''))) < 0) {
        await this.onCheck('홀덤머니가 부족합니다.')
        this.getCurrentChargeMoney = oldValue
        return
      }

      this.getCurrentChargeMoney = n

      // this.userMoney = Number(this.userData.cashAmt) + (Number(n.replace(/,/g, '')))
      // this.holMoney = this.defaultHolMoney - (Number(n.replace(/,/g, '')))
      //
    }
  },
  methods: {
    thousand,
    onFocus (e) {
      e.target.removeAttribute('readonly')
    },
    async setMoney (value) {
      const currentMoney = Number(this.setCurrentChargeMoney.replace(/,/g, ''))
      console.log(currentMoney, value)
      if (currentMoney + value > this.userData.cashAmt) {
        await this.onCheck('현재 보유머니가 부족합니다.')
        return
      }
      this.setCurrentChargeMoney = thousand(currentMoney + value)
    },
    async getMoney (value) {
      const currentMoney = Number(this.getCurrentChargeMoney.replace(/,/g, ''))
      console.log(currentMoney, value, currentMoney + value, this.defaultHolMoney)
      if (currentMoney + value > this.defaultHolMoney) {
        await this.onCheck('홀덤머니가 부족합니다.')
        return
      }
      this.getCurrentChargeMoney = thousand(currentMoney + value)
    },
    async onCharge () {
      if (this.setCurrentChargeMoney && this.setCurrentChargeMoney !== '0') {
        const confirm = await this.onConfirm('충전하시겠습니까?')
        if (confirm) {
          this.emitter.emit('Loading', true)
          this.TMmodalCharge = true
        }
      }
    },
    onChargeCancel () {
      this.TMmodalCharge = false
    },
    onExchangeCancel () {
      this.TMmodalExchange = false
    },
    async onChargeSubmit () {
      this.emitter.emit('Loading', true)
      const params = {
        amount: this.setCurrentChargeMoney.replace(/,/g, '')
      }

      console.log(params)
      chargeHolem(params).then(async res => {
        console.log(res)
        this.emitter.emit('Loading', false)
        const result = res.data
        this.TMmodalCharge = false
        if (result.resultCode === '0') {
          await this.onCheck('정상적으로 충전되었습니다.')
          this.getHoldemMoney()
          this.setCurrentChargeMoney = '0'
          location.reload()
        }
      })
    },
    async onExchange () {
      if (this.getCurrentChargeMoney && this.getCurrentChargeMoney !== '0') {
        const confirm = await this.onConfirm('환전하시겠습니까?')
        if (confirm) {
          this.emitter.emit('Loading', true)
          this.TMmodalExchange = true
        }
      }
    },
    async onExchangeSubmit () {
      this.emitter.emit('Loading', true)
      const params = {
        amount: this.getCurrentChargeMoney.replace(/,/g, '')
      }

      console.log(params)
      exchangeHolem(params).then(async res => {
        console.log(res)
        this.emitter.emit('Loading', false)
        const result = res.data
        this.TMmodalExchange = false
        if (result.resultCode === '0') {
          this.getHoldemMoney()
          this.getCurrentChargeMoney = '0'
          await this.onCheck('정상적으로 환전되었습니다.')
          location.reload()
        }
      })
    },
    getHoldemMoney () {
      this.emitter.emit('Loading', true)
      getHoldemMoney({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.holMoney = Number(result.data.nextUserBalance) || 0
          this.defaultHolMoney = this.holMoney
        }
        this.emitter.emit('Loading', false)
      })
    },
    getChargeList (cashType) {
      const params = {
        cashType: cashType, // NH1, NH2
        startDate: getDateStr(getSubDaysDate(new Date(), 3), 'yyyy-MM-dd 00:00:00'),
        endDate: getDateStr(new Date(), 'yyyy-MM-dd 23:59:59')
      }
      console.log(params)
      getHoldemList(params).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          this.holemList = result.data.list
        }
      })
    }
  },
  async created () {
    this.userMoney = this.userData.cashAmt
    this.getHoldemMoney()
    this.getChargeList('NH1')
  }
}
</script>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
<style scoped>
  body {
    background: none;
  }
  * {
    font-family: 'SUIT' !important;
  }
  #holWrap {
    width: 100%;
    padding: 20px;
    height: 749px;
    overflow: hidden;
    box-sizing: border-box;
  }
  #holWrap::-webkit-scrollbar {
    width: 0;
  }
  .menuWrap {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .menuWrap li {
    width: 32%;
    max-width: 380px;
    box-sizing: border-box;
    font-size: 18px;
    padding: 14px 0;
    border-radius: 5px;
    color: #fff;
    -webkit-backdrop-filter: blur(27.1px);
    backdrop-filter: blur(27.1px);
    box-shadow: 0 3px 3px 0 #000, inset 0 0 10px 0 #52525d;
    border: solid 1px #373139;
    cursor: pointer;
    text-align: center;
    transition: 0.5s;
  }
  .menuWrap li.currentMenu {
    box-shadow: 0 0 10px 0 #275ef3;
    background-image: linear-gradient(to right, #1233db 0%, #2966f8 50%, #1d40e0 100%);
  }
  .menuWrap li:hover {
    box-shadow: 0 0 10px 0 #275ef3;
    background-image: linear-gradient(to right, #1233db 0%, #2966f8 50%, #1d40e0 100%);
  }
  .holTab2 > div {
    box-sizing: border-box;
  }
  .holTab2 > div.hol_content {
    margin: 20px auto;
    width: 90%;
    color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 30px;
  }
  .holContWrap {
    width: 100%;
    margin: 20px auto;
    height: calc(100% - 30px);
    overflow-y: scroll;
    z-index: 1;
  }
  .hol_content > div {
    padding: 20px 25px;
    border-radius: 5px;
    border: solid 1px #373139;
    -webkit-backdrop-filter: blur(27.1px);
    backdrop-filter: blur(27.1px);
    box-shadow: 0 3px 3px 0 #000, inset 0 0 10px 0 #52525d;
  }
  .hol_menuBg{
    background: url(../../../../assets/img/hol/hol_bg.png) no-repeat;
    background-size: cover;
    background-position: center center;
  }
  .hol_menu1 button{
    width: 150px;
    height: 50px;
    position: absolute;
    bottom: 90px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 40px;
    border-radius: 5px;
    border: 0;
    font-family: 'SUIT';
    font-size: 22px;
    text-shadow: 0 3px 3px rgba(0, 0, 0, 0.53);
    font-weight: bold;
    color: #fff;
    background-image: linear-gradient(to right, #1233db, #2966f8 50%, #1d40e0);
    box-shadow: 0 0 10px 0 #275ef3;
    cursor: pointer;
  }
  .holtitle{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    margin-top: 65px;
    height: calc(100% - 200px);
  }
  .holtitle img{
    width: 38%;
  }
  .holtitle p {
    font-size: 22px;
    letter-spacing: 0.5px;
    padding-bottom: 50px;
  }
  .holtitle p br:nth-child(1) {
    display: none;
  }
  .holbg {
    position: relative;
    height: 440px;
    /* background: url(../../../../assets/img/hol/holdem.png) no-repeat;
    background-size: cover;
    background-position-x: center; */
  }
  .holContWrap::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  .holContWrap::-webkit-scrollbar-thumb{
    border-radius: 3px;
    background-color: gray;
  }
  .holContWrap::-webkit-scrollbar-track{
    background-color: transparent;
  }
  .holContWrap::-webkit-scrollbar-button{
    width: 0;
    height: 0;
  }
  .holTab2 {
    position: relative;
  }
  .tabBg{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: url(../../../../assets/img/hol/circle.svg) no-repeat;
    background-size: 150%;
    background-position: center center;
    -webkit-filter: blur(50px);
    filter: blur(50px);
    /* opacity: 0.11; */
  }
  .hol-noti{
    background: #ffffff0d;
    padding: 20px;
  }
  .hol-noti h4 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .hol-noti ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 30px;
  }
  .hol-noti ul li {
    position: relative;
    font-family: 'SUIT';
    font-size: 14px;
    font-weight: 500;
  }
  .hol-noti ul li::after {
    position: absolute;
    content: '✔';
    left: -20px;
    top: 0;
  }
  .hol-charge {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .hol-charge table {
    width: 100%;
  }
  .hol-charge tr {
    border-bottom: 1px solid #373139;
  }
  .hol-charge tr:last-child {
    border-bottom: 0;
  }
  .hol-charge td{
    padding: 15px 20px;
  }
  .hol-charge td:first-child {
    min-width: 140px;
    width: 15%;
  }
  .hol-charge td:last-child {
    position: relative;
    width: 85%;
  }
  .hol-charge td .money {
    text-shadow: 0 3px 3px rgba(0, 0, 0, 0.53);
    font-family: 'SUIT';
    font-size: 16px;
    font-weight: 800;
    color: #5c8cff;
    padding-left: 20px;
  }
  .hol-charge td ul {
    display: flex;
    gap: 5px;
  }
  .hol-charge td ul li button {
    height: 30px;
    width: 84px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #373139;
    color: #fff;
    background: none;
    border-radius: 5px;
    font-size: 13px;
    font-family: 'SUIT';
    padding: 0 10px;
    cursor: pointer;
    -webkit-backdrop-filter: blur(27.1px);
    backdrop-filter: blur(27.1px);
    box-shadow: 0 3px 3px 0 #000, inset 0 0 10px 0 #52525d;
    text-shadow: 0 3px 3px rgba(0, 0, 0, 0.53);
  }
  .hol-charge input {
    background: none;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    width: 310px;
    padding: 5px;
    margin-bottom: 10px;
    color: #5c8cff;
    padding-left: 20px;
    font-size: 16px;
    font-weight: 800;
  }
  .exBtn {
    width: 250px;
    height: 44px;
    border-radius: 5px;
    border: 0;
    font-family: 'SUIT';
    font-size: 18px;
    text-shadow: 0 3px 3px rgba(0, 0, 0, 0.53);
    font-weight: bold;
    color: #fff;
    background-image: linear-gradient(to right, #1233db, #2966f8 50%, #1d40e0);
    box-shadow: 0 0 10px 0 #275ef3;
    border: 0;
    margin: 0 auto;
    cursor: pointer;
  }
  .cancelBtn {
    width: 110px;
    height: 34px;
    border-radius: 5px;
    border: 0;
    font-family: 'SUIT';
    font-size: 14px;
    text-shadow: 0 3px 3px rgba(0, 0, 0, 0.53);
    -webkit-backdrop-filter: blur(27.1px);
    backdrop-filter: blur(27.1px);
    background: none;
    color: #fff;
    box-shadow: 0 3px 3px 0 #000, inset 0 0 10px 0 #52525d;
    border: solid 1px #373139;
    border: 0;
    margin: 0 auto;
    cursor: pointer;
  }
  .hol-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h3 {
    font-size: 22px;
    width: 100%;
  }
  .hol-list table {
    width: 100%;
  }
  .hol-list tr {
    border-bottom: 1px solid #373139;
  }
  .hol-list th {
    padding: 15px;
  }
  .hol-list td {
    padding: 15px;
    text-align: center;
  }
  .hol-list tr:last-child {
    border-bottom: 0;
  }
  .hol-list button {
    width: 112px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    background: #fff;
    border-radius: 50px;
    margin: 20px 0 40px;
    cursor: pointer;
  }
  .cashBtnWrap li:nth-child(1) button{
    background-image: radial-gradient(circle at 50% 150%, rgba(34, 175, 254, 0.7), rgba(128, 128, 128, 0) 81%);
  }
  .cashBtnWrap li:nth-child(2) button{
    background-image: radial-gradient(circle at 50% 150%, rgba(213, 171, 57, 0.7), rgba(128, 128, 128, 0) 81%);
  }
  .cashBtnWrap li:nth-child(3) button{
    background-image: radial-gradient(circle at 50% 150%, rgba(41, 183, 118, 0.7), rgba(128, 128, 128, 0) 81%);
  }
  .cashBtnWrap li:nth-child(4) button{
    background-image: radial-gradient(circle at 50% 150%, rgba(129, 69, 247, 0.7), rgba(128, 128, 128, 0) 81%);
  }
  .cashBtnWrap li:nth-child(5) button{
    background-image: radial-gradient(circle at 50% 150%, rgba(252, 94, 116, 0.7), rgba(128, 128, 128, 0) 81%);
  }
  .cashBtnWrap li:nth-child(6) button{
    background-image: radial-gradient(circle at 50% 150%, rgba(248, 127, 76, 0.7), rgba(128, 128, 128, 0) 81%);
  }
  .cashBtnWrap li:nth-child(7) button{
    background-image: radial-gradient(circle at 50% 150%, rgba(227, 64, 249, 0.7), rgba(128, 128, 128, 0) 81%);
  }
  @media (max-width: 700px) {
    #holWrap {
      padding: 20px;
    }
    .menuWrap {
      width: 100%;
    }
    .holTab2 > div.hol_content {
      width: 100%;
      margin-top: 0;
    }
    .hol_content > div {
      padding: 15px 14px;
    }
    .holtitle {
      margin-top: 45px;
    }
    .holtitle img {
      display: none;
    }
    .holtitle p {
      font-size: 20px;
      line-height: 2;
    }
    .holtitle p br:nth-child(1) {
      display: inline;
    }
    .hol-noti h4 {
      font-size: 14px;
      margin-bottom: 10px;
    }
    .hol-noti ul {
      gap: 5px;
    }
    .hol-noti ul li {
      font-size: 12px;
      line-height: 1.5;
      font-weight: 200;
    }
    .hol-charge tr {
      display: flex;
      flex-direction: column;
      border-bottom: 0;
    }
    .hol-charge td{
      padding: 10px 0;
      font-size: 13px;
      box-sizing: border-box;
    }
    .hol-charge td:first-child {
      min-width: auto;
      width: 100%;
    }
    .hol-charge td:last-child {
      width: 100%;
    }
    .hol-charge td .money {
      padding: 5px 12px;
      width: calc(100% - 48px);
      display: inline-block;
      border-radius: 5px;
      border: solid 1px #373139;
      margin-right: 7px;
    }
    .hol-charge input {
      width: calc(100% - 23px);
      margin-right: 7px;
      padding-left: 12px;
    }
    .hol-charge td ul {
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .hol-charge td ul li {
      width: calc(25% - 5px);
    }
    .hol-charge td ul li button {
      height: 30px;
      width: 100%;
      font-size: 12px;
    }
    .hol-list h3 {
      font-size: 17px;
    }
    .hol-list th {
      padding: 10px 5px;
      font-size: 12px;
    }
    .hol-list td {
      padding: 10px 5px;
      font-size: 12px;
    }
  }
</style>
